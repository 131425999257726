import React, { EventHandler, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useApi } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import NotFoundPage from "../errors/NotFoundPage";
import Components, { TemplateBlock } from "./index";

import config from "../config";
import { useTranslation } from "../context/translation";
import { RenderAllIcons, searchAndReplace } from "./DataBinding";
import { updateFrameSize } from "../utils/static";
import { useDataQuery } from "../utils/data-query";


const excludedBlocks = [
  'cms.form.block.partner_how_to'
];
const excludedPartner = [
  'amazon_de_de',
  'amazon_de_en',
  'marriottbonvoy_de_de',
  'marriottbonvoy_de_en'
]

export default function MainPage() {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();

  const {authenticated, decryptAsset} = useApi();
  const {translate} = useTranslation();

  const { initialized, data, runQuery } = useDataQuery();
  const {pathname, search} = useLocation();

  const params = new URLSearchParams(search);
  const [renderedBlocks, setRenderedBlocks] = useState(false);


  const page = data;

  useEffect(() => {
    if (authenticated && params.has('ssoData')) {
      decryptAsset(params.get('ssoData')).then(response => {
        if (response && !response.error && response.result) {
          localStorage.setItem('mam:scn', response.result);
        }
      })
    }
  }, [authenticated]);

  useEffect(() => {
    if (data?.status === 302) {
      navigate(data.messages.redirect);
    } else {
      if (data?.title) {
        document.title = `${config.baseTitle} - ${searchAndReplace(data.title, data)}`;
      }
    }
  }, [data]);

  useEffect(() => {
    if (initialized && pathname) {
      runQuery({
        api: "cms",
        call: "runApiRouter",
        args: [pathname]
      }, false);
    }
  }, [initialized, pathname]);

  useLayoutEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (page && renderedBlocks) {
        updateFrameSize();
      }
    }, 250);
  }, [renderedBlocks]);

  const renderPageBlock = (block: TemplateBlock<string>, index: number) => {
    const BlockComponent = Components[block.block_name] ?? null;

    if (!BlockComponent) {
      console.warn(block.block_name, 'not implemented');
      return null;
    }

    if ((index + 1) === page?.blocks?.length) {
      setRenderedBlocks(true);
    }

    // temporary exclude blocks fromm partner pages
    if (excludedBlocks?.includes(block.block_name) && excludedPartner.includes(page?.bonded_data?.unique_name ?? '')) {
      return null;
    }

    const content = JSON.parse(block.content ?? '{}');
    return <BlockComponent key={index} {...content.props} mapping={content.mapping} blockIndex={index} page={page} />
  };


  const goBack = (event: MouseEvent) => {
    //event.preventDefault();
    //navigate(-1);
  };

  return useMemo(() => {
    if (page?.error) {
      switch (page.status) {
        case 404:
          return <NotFoundPage />;
      }
    }

    if (page) {
      return (
        <React.Fragment>
          <main className="container">
            {(page.has_parent) && (
              <section className="mamcom_component mam_backlink_container">
                <div className="row">
                  <div className="backlink col-xs-12">
                    <a href="/" onClick={goBack as EventHandler<any>} className="backlink_anchor" data-role="none" rel="external">
                      <div className="backlink_iconWrapper">
                        <span className="mam-ico26 "></span>
                      </div>
                      <div className="backlink_text">{translate('mam.static.back')}</div>
                    </a>
                  </div>
                </div>
              </section>
            )}
            {page?.blocks?.map(renderPageBlock)}
          </main>
        </React.Fragment>
      )
    }

    return null;
  }, [page]);
}
