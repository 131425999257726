import React, { useLayoutEffect } from "react";
import Slider from "react-slick";

import $ from "jquery";

import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useImageBuilder } from "../../utils/image";
import { useLinkBuilder } from "../../utils/link";
import BlurryLoadingImage from "../BlurryLoadingImage";

export function setCarouselHeight() {
  var maxHeight = 0;
  $(".carousel .mam-slide").each(function(index) {
    $(this).height("");
    // @ts-ignore
    if ($(this).outerHeight() > maxHeight) maxHeight = $(this).outerHeight();
  });
  $(".carousel .mam-slide").height(maxHeight);
  maxHeight = maxHeight + 5;
  $(".carousel .slick-slide").height(maxHeight);
}

export function setCarouselControls() {
  let imageHeight = $(".carousel .slick-active .mam-slide .center-block>img").height();

  // @ts-ignore
  if (imageHeight < 250) $(".carousel .slick-dots").css("top", Math.round(imageHeight - 30) + "px");
  // @ts-ignore
  else $(".carousel .slick-dots").css("top", Math.round(imageHeight - 54) + "px");
  let controllers = $(".carousel .carousel-control-prev");
  // @ts-ignore
  $(".carousel .slick-arrow").css("top", (Math.round(imageHeight * 0.5) - (Math.round(controllers.height() * 0.5))) + "px");
  // @ts-ignore
  //$(".carousel .carousel-control-next").css("top", (Math.round(imageHeight * 0.5) - (Math.round(controllers.height() * 0.5))) + "px");
}

type SlickSliderProps = {
  id?: string;
  items: Array<any>;
  settings?: any;
}

type SlideItemProps<T> = {
  className?: string;
  item?: T | any;
  style?: CSSStyleSheet;
}

type SlickArrowProps = {
  currentSlide: number;
  slideCount: number;
  onClick: () => void;
};

function SlideItem<T>(props: SlideItemProps<T>) {
  const { buildImageUrl } = useImageBuilder();
  const { buildLinkByPage } = useLinkBuilder();

  const {
    item,
    style
  } = props;

  return (
    <div className="mam-slide">
      <a className="carousel_link" href={item.link ? buildLinkByPage(item.link) : undefined} rel="internal">
        <div className="center-block">
          <BlurryLoadingImage
            preview={buildImageUrl(item.image, 180, 73)}
            image={buildImageUrl(item.image, 2560, 1024)}
            alt={item?.image?.file_id ?? "Image"}
          />
          {item?.flag && (<div className="flag" dangerouslySetInnerHTML={{__html: item.flag}} />)}
        </div>
        <div className="card-img-overlay row">
          <div className="col-xs-11 col-sm-11 offset-sm-1">
            <div className="row">
              <div className="col-xs-11 col-sm-11 col-lg-3 left">
                <img src={buildImageUrl(item.logo, 202, 128)} alt={item.logo?.file_id} />
              </div>
              <div className="col-xs-11 col-sm-11 col-lg-9 right">
                <div className="headline" dangerouslySetInnerHTML={{ __html: item?.headline }} />
                <div className="benefit" dangerouslySetInnerHTML={{ __html: item?.text }} />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

const defaultSlickSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  arrows: false
};

export default function SlickSlider({
                                      id = "slick-slider",
                                      settings = defaultSlickSliderSettings,
                                      items
                                    }: SlickSliderProps) {
  const resizeIt = () => {
    setTimeout(() => setCarouselHeight(), 125);
    setTimeout(() => setCarouselControls(), 150);
  };

  useLayoutEffect(() => {
    resizeIt();

    window.addEventListener("resize", resizeIt);
    return () => {
      window.removeEventListener("resize", resizeIt);
    };
  }, []);

  const renderSlideItem = (item: any, index: number) => {
    if (!item) return null;
    return <SlideItem key={index} item={item} />;
  };

  if (items.length === 0) return null;

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }: SlickArrowProps) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" +
        (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0}
    >
      <button type="button" className="carousel-control-prev">
        <span className="mam-ico21"></span>
      </button>
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }: SlickArrowProps) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1}
    >
      <button className="carousel-control-next">
        <span className="mam-ico19"></span>
      </button>
    </div>
  );


  return (
    <Slider
      className="carousel"
      useCSS
      {...{
        ...settings,
        // @ts-ignore
        prevArrow: <SlickArrowLeft />,
        // @ts-ignore
        nextArrow: <SlickArrowRight />
      }}
    >
      {items.map(renderSlideItem)}
    </Slider>
  );
}