import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { PageItem, PageListItem } from "../index";
import DataBinding, { getLocaleText } from "../DataBinding";

import { initScnOverlay, scnInputFocus } from "../../utils/jquery";
import { useApi } from "../../api";
import { useTranslation } from "../../context/translation";
import { useDateFormatter } from "../../utils/date";
import { useLinkBuilder } from "../../utils/link";
import { makeHash } from "../../utils/static";
import { PartnerLinkResponse } from "../../api/partner";

type PartnerExitOverlayProps = {
  page?: PageItem;
  headline?: string;
  conditionPage?: PageListItem;
  terms?: string;
}

const ignoredBtnHashes = [
  "32cd0a7a98f4820bea22476c0cf4cf64",
  "dfb4414c48b27542495bdd0e8c6d1c33"
];

function makeSpace(string: string) {
  if (string.length > 4 && string != "undef") {
    let v = string?.replace(/[^\d\w]/g, "").match(/.{1,4}/g);
    if (v !== null) {
      let xv = v.join("  ");
      string = xv;
    }
  }
  return string;
}

export default function PartnerExitOverlay({ page, headline, conditionPage, terms }: PartnerExitOverlayProps) {
  const linkRef = useRef<any>();

  const { check, partner } = useApi();
  const { translate } = useTranslation();
  const { formatDate } = useDateFormatter();
  const { buildLinkByPage } = useLinkBuilder();

  const inputRef = useRef<HTMLInputElement>();

  const [open, setOpen] = useState(false);
  const [userRef, setUserRef] = useState<string>();
  const [validRef, setValidRef] = useState<boolean>();

  const vouchers = (page?.bonded_data?.currently_active_hints ?? []).filter((item: any) => item.type === "reduction");
  const links = page?.bonded_data?.currently_active_links ?? [];

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    initScnOverlay();
    const localScn = localStorage.getItem("mam:scn");
    if (localScn) {
      setUserRef(makeSpace(localScn));
      if (inputRef.current) {
        scnInputFocus(inputRef.current);
      }
    }

    document.addEventListener('openExitModal', openModal);
    return () => {
      document.removeEventListener('openExitModal', openModal);
    }
  }, []);

  const loadUrls = () => {
    if (userRef && validRef && open && page?.bonded_data?.id && typeof linkRef.current === "undefined") {
      const props = {
        userRef: userRef.replace(/ /g, ""),
        binding: {
          type: "partner",
          id: page.bonded_data.id,
        },
      };
      partner.getPartnerLinks(page.bonded_data.id, props).then((response: PartnerLinkResponse) => {
        if (response && !response.error) {
          linkRef.current = response;
        }
      });
    }
  } ;

  useEffect(() => {
    if (userRef && userRef.length > 3) {
      check.validateRef(userRef.replace(/ /g, "")).then((response: any) => {
        if (response && !response.error) {
          setValidRef(response.valid);
          if (response.valid) {
            localStorage.setItem("mam:scn", userRef.replace(/ /g, "") ?? "");
          }
        }
      });
    }
  }, [userRef]);

  useEffect(loadUrls, [open, validRef]);

  const onInputChange = (event: ChangeEvent) => {
    // @ts-ignore
    if (event.target.value.length === 0) {
      setUserRef("");
      setValidRef(undefined);
    } else {
      // @ts-ignore
      setUserRef(makeSpace(event.target.value));
    }
  };

  const clearInput = () => {
    setUserRef("");
    setValidRef(undefined);
  };

  const exitNow = (linkId: string) => {
    const url = linkRef.current?.[linkId] ?? null;
    if (url) {
      window.open(url, '_blank');
    }
  };

  return useMemo(() => {

    const renderLinks = (withSpace: boolean = false) => {
      return links.map((link: any, linkIndex: number) => {
        const btnText = getLocaleText(link.title, page);
        const textHash = makeHash(btnText);
        return (
          <div key={`link-${linkIndex}`} className={`shop_button ${withSpace ? `mb-3` : ``}`}>
            <button disabled={!validRef} onClick={() => exitNow(link.id)} className="exit_now button button--filled button--primary">
              <span
                className="button_text"
                dangerouslySetInnerHTML={{
                  __html: (ignoredBtnHashes.includes(textHash) || btnText.length === 0) ? translate("mam.blocks.partner.exit.shop_btn") : btnText
              }}></span>
            </button>
          </div>
        );
      });
    };

    const renderVoucher = (voucher: any, voucherIndex: number) => {
      const copyVoucher = () => {
        navigator.clipboard.writeText(voucher.voucher_code);
      };

      return (
        <div key={`voucher-${voucherIndex}`} className="button_voucher_container">
          <div className="col-xs-12 col-lg-8">
            <h4><strong>{translate("mam.blocks.partner.exit.voucher_code.title")}</strong></h4>
            <p className="voucher_text">
              <span dangerouslySetInnerHTML={{ __html: getLocaleText(voucher.title, page) }} />
              {voucher.end_date && (
                <>
                  <br />
                  <span>{`${translate("mam.blocks.partner.exit.voucher_code.valid_to")}: ${formatDate(voucher.end_date)}`}</span>
                </>
              )}
              {voucher.voucher_code && (
                <>
                  <br />
                  <span>{translate("mam.blocks.partner.exit.voucher_code.code")}: <span
                    className="code">{voucher.voucher_code}</span></span>
                </>
              )}
              {(voucher?.total_min > 0) && (
                <>
                  <br />
                  <span>{translate("mam.blocks.partner.exit.voucher_code.total_min")}: {`${voucher.total_min} ${page?.bonded_data.currency}`}</span>
                </>
              )}
            </p>
          </div>
          <div className="col-xs-12 col-lg-4">
            {voucher.voucher_code && (
              <div className="voucher_button">
                <button className="voucher_copy_button button button--filled button--offline" onClick={copyVoucher}
                        data-role="none">
                  <span className="button_text">{translate("mam.blocks.partner.exit.voucher_code.copy_btn")}</span>
                </button>
              </div>
            )}
            {voucherIndex === 0 && renderLinks()}
          </div>
        </div>
      );
    };

    return (
      <section className={`mamcom_component mam_overlay_container ${open ? `active` : ``}`}>
        <div className="overlay_box">
          <div className="row">
            <div className="icon_container col-xs-12" onClick={closeModal}>
              <span className="mam-ico6 icon"></span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8 offset-sm-2">
              <div className="heading_content col-xs-12">
                <h1 className="heading_text">
                  <DataBinding text={headline ?? ""} data={page} />
                </h1>
              </div>
            </div>
            <div className="col-sm-8 offset-sm-2">
              <div className="servicecard_container">
                <div className="heading">
                  <h3>{translate("mam.blocks.partner.exit.user_ref.title")}</h3>
                  <h4><b>{translate("mam.blocks.partner.exit.user_ref.sub_title")}</b></h4>
                  <p>{translate("mam.blocks.partner.exit.user_ref.text")}:</p>
                </div>
                <div className="form_elements">
                  <div className="form_element_wrapper">
                    <div className="servicecard_input inputfield_3">
                      <div
                        className={`form_textfield ${(userRef?.length && userRef.length > 0) && "filled"} ${typeof validRef === "undefined" ? `` : validRef ? `correct` : `incorrect`}`}>
                        <input
                          ref={(ref) => {
                            if (ref) {
                              inputRef.current = ref;
                            }
                          }}
                          className={`form_textfield_input`}
                          data-role="none"
                          id="mm_scn"
                          value={userRef ?? ''}
                          type="tel"
                          name="mm_scn"
                          placeholder={translate("mam.blocks.partner.exit.user_ref.input.placeholder")}
                          onChange={onInputChange}
                        />
                        <label className="form_textfield_label"
                               htmlFor="servicecard_input">{translate("mam.blocks.partner.exit.user_ref.input.label")}</label>
                        <span className="form_textfield_icon_cleaner" onClick={clearInput}>
                        <span
                          className={`mamcomicon--baseline ${typeof validRef === "undefined" ? `` : validRef ? `mam-ico11` : `mam-ico12`}`}></span>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {vouchers?.map(renderVoucher)}
              {vouchers.length === 0 && (
                <div className="button_voucher_container">
                  <div className="col-xs-12 col-lg-8"></div>
                  <div className="col-xs-12 col-lg-4">
                    {renderLinks(true)}
                  </div>
                </div>
              )}
              <div className="terms_container">
                <p className="hint">
                  <b>{translate("mam.blocks.partner.exit.cookies.strong")}:</b> {translate("mam.blocks.partner.exit.cookies.light")}
                </p>
                {conditionPage && (
                  <div className="alert_box">
                    <a className="carousel_link" rel="internal">
                      <span className="mam-ico31"></span>
                      <span>{translate("mam.blocks.partner.exit.condition_page.title")}</span>
                    </a>
                  </div>
                )}
                <div className="conditions">
                  <h3>{translate("mam.blocks.partner.exit.conditions.title")}</h3>
                  <ul
                    dangerouslySetInnerHTML={{ __html: page ? getLocaleText(page?.bonded_data?.partner_terms ?? "", page) : "" }} />
                  <br />
                  <DataBinding text={terms ?? ""} data={page} />
                </div>
              </div>
              <div className="button_voucher_container">
                <div className="col-xs-12 col-md-8">
                </div>
                {typeof links[0] !== "undefined" && (
                  <div className="col-xs-12 col-md-4">
                    <div className="shop_button">
                      <button className="exit_now button button--filled button--primary"
                              onClick={() => exitNow(links[0].id)}
                              disabled={!validRef}>
                        <span className="button_text">{translate("mam.blocks.partner.exit.shop_btn")}</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
      ;
  }, [open, page, headline, conditionPage, terms, userRef, validRef]);
}